import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TimerComponent from 'components/Timer/TimerComponent';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import analyticsService, { AnalyticsEvent } from 'services/AnalyticsService';
import { Color } from 'modules/styles/colors';
import { formatAlert, Step } from 'store/pageActions/RegisterAccount/constants';
import {
  sendVerficationCode,
  updateRegistrationAlert,
  updateRegistrationData,
  updateRegistrationStep,
  validateVerificationCode,
  verifyPerson,
  registerContactSelector,
  registerSubmittingSelector,
  AlertType,
  getRegisterBrand,
  RegisterAlert,
  RegisterData
} from 'store/register';
import { RootState } from 'store/types';
import { MuiBox } from 'theme/material-ui';
import * as Yup from 'yup';
import { CancelButton } from '../FormElements/CancelButton';
import CustomButton from '../FormElements/CustomButton';
import CustomTextField from '../FormElements/CustomTextField';
import { StyledTypography } from '../styled';
import { getUSPhoneNumber } from 'modules/utils/PhoneUtils';
import useStyles from '../useStyles';

interface Values extends RegisterData {
  smsCode: string;
}

export interface Props {
  initialValues: {
    phone: string;
  };
  updateRegister: (data: Partial<RegisterData>) => void;
  updateAlert: (data: RegisterAlert) => void;
  sendCode: (type: string) => Promise<AnyAction>;
  validateCode: (type: string, pin: string) => Promise<AnyAction>;
  updateStep: (step: string) => void;
  verifyId: () => Promise<AnyAction>;
  isSubmitting: boolean;
}

const ContactSchema = Yup.object().shape({
  phone: Yup.string().required('Required'),
  smsCode: Yup.string().required('Required')
});

export const RegisterVerifyPhone = ({
  initialValues,
  updateAlert,
  updateRegister,
  sendCode,
  validateCode,
  updateStep,
  isSubmitting
}: Props) => {
  const redirectBrand = getRegisterBrand();
  const classes = useStyles({ redirectBrand });

  const [codeSent, setCodeSent] = useState<boolean>(false);
  const [sendingCode, setSendingCode] = useState<boolean>(false);
  const [showTimer, setShowTimer] = useState(true);
  const [disableResend, setDisableResend] = useState(true);

  const logCodeSent = (attempt: string) => {
    analyticsService.logEvent(AnalyticsEvent.AccountCreationValidationCodeSent, {
      verification_goal: 'phone_number',
      verification_type: 'text_code',
      attempt
    });
  };

  useEffect(() => {
    handleSendCode();
    logCodeSent('initial');
  }, []);

  const handleSendCode = (phone?: string, resend?: boolean) => {
    setSendingCode(true);
    if (resend) {
      setDisableResend(true);
    }
    if (codeSent) {
      logCodeSent('resend');
    } else {
      setCodeSent(true);
    }

    if (!!phone) {
      updateRegister({ phone });
    }
    updateAlert(
      formatAlert(AlertType.INFO, 'Verify Phone', 'Please enter the code sent to your phone below.')
    );

    const handleError = () => {
      updateAlert(
        formatAlert(
          AlertType.ERROR,
          'Uh oh!',
          'We are having trouble texting your code. Please try resending the code below.'
        )
      );
    };
    sendCode('sms')
      .then(res => {
        setDisableResend(false);
        setShowTimer(false);
        setSendingCode(false);
        if (res.error || res.payload?.data?.success === false) {
          handleError();
        } else {
          setShowTimer(true);
          setDisableResend(true);
        }
      })
      .catch(handleError);
  };

  const handleSubmit = (values: Partial<Values>) => {
    const { phone, smsCode } = values;
    updateRegister({ phone });
    const handleError = () => {
      updateAlert(
        formatAlert(AlertType.ERROR, 'Uh oh!', 'Verification code is incorrect. Please try again.')
      );
    };
    if (!smsCode) {
      handleError();
      return;
    }
    validateCode('sms', smsCode)
      .then(res => {
        setDisableResend(false);
        if (res.error || res.payload?.data?.success === false) {
          handleError();
        } else {
          updateAlert(formatAlert());
          updateStep(Step.ACCOUNT);
        }
      })
      .catch(handleError);
  };

  return (
    <Formik
      initialValues={{
        ...initialValues,
        smsCode: ''
      }}
      validationSchema={ContactSchema}
      onSubmit={handleSubmit}
    >
      {props => (
        <Form>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <MuiBox bgcolor={Color.baseColor} flex={1}>
                <StyledTypography>{getUSPhoneNumber(initialValues.phone)}</StyledTypography>
              </MuiBox>
            </Grid>
            <Grid item xs={12}>
              <CustomTextField
                name="smsCode"
                mask="securityCode"
                label="Security Code"
                placeholder="000000"
                button={
                  <Button
                    type="button"
                    size="large"
                    className={classes.customTextButton}
                    disabled={disableResend}
                    onClick={() => {
                      handleSendCode(props.values.phone, true);
                    }}
                  >
                    Resend code
                  </Button>
                }
              />
              <Typography>
                {showTimer ? (
                  <TimerComponent
                    isExpired={() => {
                      setShowTimer(false);
                      setDisableResend(false);
                    }}
                    showTimer={showTimer}
                    interval={3}
                  />
                ) : null}
                {sendingCode ? (
                  <Typography>
                    <Box>
                      <span style={{ fontWeight: 'bold' }}>Sending Code...</span>
                    </Box>
                  </Typography>
                ) : null}
              </Typography>
            </Grid>
          </Grid>
          <Box my={3} maxWidth="xs" textAlign="center">
            <CustomButton
              type="submit"
              label="Continue"
              disabled={!(props.isValid && props.dirty)}
              isSubmitting={isSubmitting}
              aria-label="submit-button"
            />
            <CancelButton />
          </Box>
        </Form>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: RootState) => ({
  initialValues: registerContactSelector(state),
  isSubmitting: registerSubmittingSelector(state)
});

const mapDispatchToProps = {
  updateRegister: updateRegistrationData,
  sendCode: sendVerficationCode,
  validateCode: validateVerificationCode,
  updateStep: updateRegistrationStep,
  verifyId: verifyPerson,
  updateAlert: updateRegistrationAlert
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterVerifyPhone);
